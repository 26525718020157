<template>
    <div>
        <div class="header-background">
            <div class="header-background-content">
                <div class="header-background-text">1万名旅行定制师，</div>
                <div class="header-background-text">
                    服务覆盖全球178个国家。
                </div>
                <div class="header-background-btn">定制旅游</div>
            </div>
        </div>
        <div class="baseinfo-box">
            <div class="baseinfo-title">基本信息</div>
            <div class="baseinfo">
                <div class="baseinfo-form" ref="baseinfoForm">
                    <div class="baseinfo-form-row">
                        <span class="baseinfo-form-key">出发地</span
                        ><input
                            type="text"
                            v-model="tourCustomParams.startCity"
                            class="baseinfo-form-value"
                            placeholder="请输入出发地(例:上海)"
                        />
                    </div>
                    <div class="baseinfo-form-row">
                        <span class="baseinfo-form-key">目的地</span
                        ><input
                            type="text"
                            v-model="tourCustomParams.targetCity"
                            class="baseinfo-form-value"
                            placeholder="请输入目的地(例:北京)"
                        />
                    </div>
                    <div class="baseinfo-form-row">
                        <span class="baseinfo-form-key">出行日期</span
                        ><input
                            type="text"
                            v-model="tourCustomParams.startDate"
                            class="baseinfo-form-value"
                            placeholder="请输入日期(例:2021-01-01)"
                        />
                    </div>
                    <div class="baseinfo-form-row">
                        <span class="baseinfo-form-key">返程日期</span
                        ><input
                            type="text"
                            v-model="tourCustomParams.endDate"
                            class="baseinfo-form-value"
                            placeholder="请输入日期(例:2021-01-01)"
                        />
                    </div>
                    <div class="baseinfo-form-row">
                        <span class="baseinfo-form-key">成人出行人数</span
                        ><input
                            type="text"
                            v-model="tourCustomParams.personNum"
                            class="baseinfo-form-value"
                            placeholder="请输入成人出行人数(例:1)"
                        />
                    </div>
                    <div class="baseinfo-form-row">
                        <span class="baseinfo-form-key">儿童出行人数</span
                        ><input
                            type="text"
                            v-model="tourCustomParams.childrenNum"
                            class="baseinfo-form-value"
                            placeholder="请输入儿童出行人数(例:1)"
                        />
                    </div>
                    <div class="baseinfo-form-row">
                        <span class="baseinfo-form-key">人均预算</span
                        ><input
                            type="text"
                            v-model="tourCustomParams.avgBudget"
                            class="baseinfo-form-value"
                            placeholder="请输入您的预算(例:5000)"
                        />
                    </div>
                    <div class="baseinfo-form-row">
                        <span class="baseinfo-form-key">手机号</span
                        ><input
                            type="text"
                            v-model="tourCustomParams.contact"
                            class="baseinfo-form-value"
                            placeholder="请输入您的手机号"
                        />
                    </div>
                </div>
                <div class="need-server">
                    <div class="need-server-title">需要提供服务（可多选）</div>
                    <div class="need-server-btns">
                        <div
                            v-for="item in needServerBtnList"
                            :key="item.id"
                            class="need-server-btn"
                            :class="
                                item.selected ? 'need-server-btn-selected' : ''
                            "
                            @click="needServerBtnSelect(item.id, item.label)"
                        >
                            {{ item.label }}
                        </div>
                    </div>
                </div>
                <div class="hobbies">
                    <div class="hobbies-title">偏好需求</div>

                    <div class="hobbies-content">
                        <textarea
                            v-model.trim="tourCustomParams.otherNeeds"
                            class="hobbies-content-text"
                            cols="30"
                            rows="10"
                            placeholder="您可以在此输入个性化需求，如：同行人中是否含婴儿、老人，希望定制师何时联系您，对酒店、景区、出行方式的具体要求等"
                        ></textarea>
                    </div>
                </div>
                <div class="submit-box">
                    <div class="submit" @click="submit">马上为我定制</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { tourCustom } from "@/http/api";
export default {
    data() {
        return {
            needServerBtnList: [
                {
                    id: 1,
                    label: "线路设计",
                    selected: false,
                },
                {
                    id: 2,
                    label: "机票",
                    selected: false,
                },
                {
                    id: 3,
                    label: "火车票",
                    selected: false,
                },
                {
                    id: 4,
                    label: "用车",
                    selected: false,
                },
                {
                    id: 5,
                    label: "导游",
                    selected: false,
                },
                {
                    id: 6,
                    label: "签证",
                    selected: false,
                },
                {
                    id: 7,
                    label: "酒店",
                    selected: false,
                },
                {
                    id: 8,
                    label: "门票",
                    selected: false,
                },
                {
                    id: 9,
                    label: "民宿",
                    selected: false,
                },
                {
                    id: 10,
                    label: "用餐",
                    selected: false,
                },
            ],
            labelList: [],
            hobbyBtnList: [
                {
                    id: 1,
                    label: "探险",
                    selected: true,
                },
                {
                    id: 2,
                    label: "美食",
                    selected: false,
                },
                {
                    id: 3,
                    label: "蜜月",
                    selected: false,
                },
                {
                    id: 4,
                    label: "网红打卡",
                    selected: false,
                },
                {
                    id: 5,
                    label: "其它",
                    selected: false,
                },
            ],
            lazyPersonMoveBoxMoving: true,
            tourCustomParams: {
                startCity: "",
                targetCity: "",
                startDate: "",
                endDate: "",
                personNum: "",
                childrenNum: "",
                avgBudget: "",
                contact: "",
                serviceNeeds: "",
                otherNeeds: "",
            },
            tourCustomDesc: {
                avgBudget: "人均预算",
                childrenNum: "儿童出行人数",
                contact: "手机号",
                endDate: "返程日期",
                otherNeeds: "偏好需求",
                personNum: "成人出行人数",
                serviceNeeds: "需要提供服务",
                startCity: "出发地",
                startDate: "出行日期",
                targetCity: "目的地",
            },
        };
    },
    // mounted() {
    //   this.$refs.lazyPersonMoveBox.addEventListener(
    //     "transitionrun",
    //     function () {
    //       this.lazyPersonMoveBoxMoving = false;
    //     }.bind(this)
    //   );
    //   this.$refs.lazyPersonMoveBox.addEventListener(
    //     "transitionend",
    //     function () {
    //       this.lazyPersonMoveBoxMoving = true;
    //     }.bind(this)
    //   );
    // },
    methods: {
        needServerBtnSelect(id, label) {
            this.needServerBtnList.some((item, index) => {
                if (item.id === id) {
                    item.selected = !item.selected;
                    if (item.selected) {
                        this.labelList.push(label);
                        this.tourCustomParams.serviceNeeds = this.labelList.join(
                            ","
                        );
                    } else {
                        this.labelList.splice(index, 1);
                        this.tourCustomParams.serviceNeeds = this.labelList.join(
                            ","
                        );
                    }
                    return true;
                }
            });
        },
        hobbyBtnSelect(id) {
            this.hobbyBtnList.some((item) => {
                if (item.id === id) {
                    item.selected = !item.selected;
                    return true;
                }
            });
        },
        imgMoveNext(moveBox, container, prevBtn, nextBtn, itemWidth, imgSize) {
            // imgSize: 页面中显示的张数
            if (!this.lazyPersonMoveBoxMoving) {
                return;
            }
            const pBtn = this.$refs[prevBtn];
            const nBtn = this.$refs[nextBtn];
            const imgMoveBox = this.$refs[moveBox];
            const imgContainer = this.$refs[container].offsetWidth;
            const maxLength =
                imgMoveBox.children[imgMoveBox.children.length - 1].offsetLeft;
            if (Math.abs(imgMoveBox.offsetLeft) >= maxLength - imgContainer) {
                return;
            }
            imgMoveBox.style.left = imgMoveBox.offsetLeft + -itemWidth + "px";
            // 隐藏右侧按钮
            console.log(
                imgMoveBox.children.length * itemWidth,
                Math.abs(imgMoveBox.offsetLeft - itemWidth * (imgSize + 1))
            );
            if (
                imgMoveBox.children.length * itemWidth ===
                Math.abs(imgMoveBox.offsetLeft - itemWidth * (imgSize + 1))
            ) {
                nBtn.style.display = "none";
            } else {
                nBtn.style.display = "block";
            }
            // 隐藏左侧按钮
            if (Math.abs(imgMoveBox.offsetLeft) >= 0) {
                pBtn.style.display = "block";
            } else {
                pBtn.style.display = "none";
            }
        },

        imgMovePrev(moveBox, container, prevBtn, nextBtn, itemWidth, imgSize) {
            if (!this.lazyPersonMoveBoxMoving) {
                return;
            }
            const pBtn = this.$refs[prevBtn];
            const nBtn = this.$refs[nextBtn];
            const imgMoveBox = this.$refs[moveBox];
            if (Math.abs(imgMoveBox.offsetLeft) <= 0) {
                return;
            }
            imgMoveBox.style.left = imgMoveBox.offsetLeft + itemWidth + "px";
            // 隐藏右侧按钮
            if (
                imgMoveBox.children.length * itemWidth ===
                Math.abs(imgMoveBox.offsetLeft - itemWidth * imgSize + 1)
            ) {
                nBtn.style.display = "none";
            } else {
                nBtn.style.display = "block";
            }
            // 隐藏左侧按钮
            if (Math.abs(imgMoveBox.offsetLeft) <= itemWidth) {
                pBtn.style.display = "none";
            } else {
                pBtn.style.display = "block";
            }
        },
        async submit() {
            let flag = true;
            let keys = Object.keys(this.tourCustomParams);
            keys.some((key, index) => {
                if (
                    key !== "serviceNeeds" &&
                    key !== "otherNeeds" &&
                    this.tourCustomParams[key] === ""
                ) {
                    const content = this.$refs.baseinfoForm.children[index]
                        .children[0].innerHTML;
                    alert(`请输入${content}`);
                    flag = false;
                    return true;
                }
            });
            if (!flag) {
                return;
            }
            const reg = /^1[3456789]\d{9}$/;
            if (!reg.test(this.tourCustomParams.contact)) {
                alert("请输入正确的手机号");
                return;
            }
            const dateReg = /\d{4}-\d{2}-\d{2}/;
            if (
                !dateReg.test(this.tourCustomParams.startDate) ||
                !dateReg.test(this.tourCustomParams.endDate)
            ) {
                alert("请输入正确的日期格式:如2021-01-01");
                return;
            }
            if (
                +new Date(this.tourCustomParams.startDate + " 23:59:59") <
                +new Date()
            ) {
                alert("开始日期不能早于今日");
                return;
            }
            if (
                this.tourCustomParams.startDate >= this.tourCustomParams.endDate
            ) {
                alert("开始日期需早于结束日期");
                return;
            }
            if (
                this.tourCustomParams.personNum === "0" &&
                this.tourCustomParams.childrenNum === "0"
            ) {
                alert("出行人数不能都为0");
                return;
            }
            if (this.tourCustomParams.avgBudget === "0") {
                alert("预算不能为0");
                return;
            }
            if (this.tourCustomParams.otherNeeds.length > 500) {
                alert("偏好需求字数不能大于500");
                return;
            }
            if (this.tourCustomParams.serviceNeeds === "") {
                this.tourCustomParams.serviceNeeds = "无";
            }
            if (this.tourCustomParams.otherNeeds === "") {
                this.tourCustomParams.otherNeeds = "无";
            }
            const { data } = await tourCustom(this.tourCustomParams);
            if (data.code === 200) {
                this.tourCustomParams = {
                    avgBudget: "",
                    childrenNum: "",
                    contact: "",
                    endDate: "",
                    otherNeeds: "",
                    personNum: "",
                    serviceNeeds: "",
                    startCity: "",
                    startDate: "",
                    targetCity: "",
                };
                this.needServerBtnList.forEach((item) => {
                    item.selected = false;
                });
                alert("需求定制成功");
            }
        },
    },
};
</script>

<style scoped lang="scss">
.header-background {
    background: url(https://oss.cuttlefish.vip/file/backstageManagement/serveManage/1625645982117.png)
        no-repeat;
    background-size: cover;
    .header-background-content {
        align-items: center;
        padding: 100px 20px 60px;
        .header-background-text {
            font-size: 18px;
            font-family: PingFang-SC-Heavy, PingFang-SC;
            font-weight: 800;
            color: #ffffff;
            text-align: center;
            margin-bottom: 10px;
        }
        .header-background-btn {
            text-align: center;
            background: #ffffff;
            border-radius: 44px;
            padding: 10px;
            font-size: 12px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #00a0ff;
            width: 60%;
            margin: 0 auto;
        }
    }
}
.baseinfo-box {
    box-shadow: 0px 0px 60px 0px rgba(39, 57, 77, 0.18);
    border-radius: 0 0 28px 28px;
    padding: 20px;
    box-sizing: border-box;
    .baseinfo-title {
        font-size: 24px;
        font-weight: 800;
        margin-bottom: 20px;
        color: #333333;
    }
    .baseinfo {
        .baseinfo-form {
            .baseinfo-form-row {
                margin-bottom: 18px;
                .baseinfo-form-key {
                    font-size: 16px;
                    font-family: PingFang-SC-Heavy, PingFang-SC;
                    font-weight: 800;
                    color: #333333;
                }
                .baseinfo-form-value {
                    box-sizing: border-box;
                    width: 100%;
                    height: 40px;
                    outline: none;
                    border: 0;
                    padding: 0 20px;
                    font-size: 16px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    background-color: #f6f6f6;
                    margin-top: 10px;
                }
            }
        }
        .need-server {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-top: 30px;
            .need-server-title {
                font-size: 24px;
                font-family: PingFang-SC-Heavy, PingFang-SC;
                font-weight: 800;
                color: #333333;
                margin-bottom: 20px;
            }
            .need-server-btns {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                align-content: space-between;
                .need-server-btn {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 47%;
                    height: 68px;
                    background: #f5f7f9;
                    border-radius: 34px;
                    font-size: 22px;
                    font-family: PingFang-SC-Heavy, PingFang-SC;
                    font-weight: 800;
                    color: #333333;
                    cursor: pointer;
                    margin-bottom: 10px;
                }
                .need-server-btn-selected {
                    background: linear-gradient(
                        90deg,
                        #00ceff 0%,
                        #00a0ff 100%
                    );
                    color: #ffffff;
                }
            }
        }
        .hobbies {
            margin-top: 20px;
            .hobbies-title {
                font-size: 24px;
                font-family: PingFang-SC-Heavy, PingFang-SC;
                font-weight: 800;
                color: #333333;
                margin-bottom: 20px;
            }
            .hobbies-btns {
                display: flex;
                justify-content: space-between;
                .hobbies-btn {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 184px;
                    height: 68px;
                    background: #f5f7f9;
                    border-radius: 34px;
                    font-size: 22px;
                    font-family: PingFang-SC-Heavy, PingFang-SC;
                    font-weight: 800;
                    color: #333333;
                    cursor: pointer;
                }
                .hobbies-btn-selected {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 184px;
                    height: 68px;
                    background: linear-gradient(
                        90deg,
                        #00ceff 0%,
                        #00a0ff 100%
                    );
                    border-radius: 34px;
                    font-size: 22px;
                    font-family: PingFang-SC-Heavy, PingFang-SC;
                    font-weight: 800;
                    color: #ffffff;
                    cursor: pointer;
                }
            }
            .hobbies-content {
                height: 232px;
                .hobbies-content-text {
                    padding: 20px;
                    outline: none;
                    resize: none;
                    border: 0;
                    width: 100%;
                    height: 100%;
                    background: #f5f7f9;
                    border-radius: 8px;
                    font-size: 16px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #898989;
                    line-height: 32px;
                    box-sizing: border-box;
                }
            }
        }
        .submit-box {
          margin-top: 20px;
            .submit {
              padding: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                background: #26a1ff;
                border-radius: 44px;
                font-size: 16px;
                font-family: PingFang-SC-Heavy, PingFang-SC;
                font-weight: 800;
                color: #ffffff;
                cursor: pointer;
            }
        }
    }
}
.lazy-person {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 88px;
    height: 348px;
    .prev-btn {
        display: none;
        position: absolute;
        top: 50%;
        left: -34px;
        transform: rotate(180deg);
        z-index: 999;
        width: 68px;
        height: 68px;
        cursor: pointer;
    }
    .next-btn {
        position: absolute;
        top: 50%;
        right: -34px;
        z-index: 999;
        width: 68px;
        height: 68px;
        cursor: pointer;
    }
    .lazy-person-title {
        font-size: 28px;
        font-family: PingFang-SC-Heavy, PingFang-SC;
        font-weight: 800;
        color: #333333;
    }
    .lazy-person-img-box {
        position: relative;
        overflow: hidden;
        height: 250px;

        .lazy-person-move-box {
            position: absolute;
            top: 0;
            left: 0;
            width: 500%;
            height: 250px;
            transition: all 1s;
            .lazy-person-img {
                float: left;
                margin-right: 24px;
                border-radius: 18px;
                width: 384px;
                height: 250px;
                background-color: #432d13;
                .lazy-person-img-text-box {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    margin: 30px;
                    width: 178px;
                    height: 190px;
                }
                .lazy-person-img-city {
                    font-size: 28px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #ffffff;
                }
                .lazy-person-img-pinyin {
                    margin-bottom: 54px;
                    font-size: 22px;
                    font-family: LucidaGrande-Bold, LucidaGrande;
                    font-weight: bold;
                    color: #ffffff;
                }
                .lazy-person-img-food {
                    width: 178px;
                    height: 60px;
                    font-size: 22px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #ffffff;
                    line-height: 30px;
                }
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}
</style>
